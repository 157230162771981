<template>
  <div class="misc-wrapper flex-column">
    <div class="d-flex justify-content-center">
      <vuexy-logo />
      <h2 class="brand-text text-primary ml-1">
        Vuexy
      </h2>
    </div>
    <div class="misc-inner p-2 p-sm-3 text-center">
      <b-card class="px-4 py-2">
        <feather-icon
          icon="CheckCircleIcon"
          size="80"
          class="text-success"
        />
        <h1 class="my-2">
          สร้างบัญชีสำเร็จ
        </h1>
        <h4 class="text-secondary mb-2">
          สร้างบัญชีสำเร็จ กรุณาเข้าสู่ระบบด้วยบัญชีของคุณ
        </h4>
        <b-button
          variant="primary"
          :to="{ name: 'agents_list'}"
        >
          กลับหน้าเข้าสู่ระบบ
        </b-button>
      </b-card>
    </div>
  </div>
  <!-- / Under maintenance-->
</template>

<script>
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  components: {
    VuexyLogo,
  },
  data() {
    return {}
  },
  setup() {
    const { t } = useI18nUtils()

    return {
      t,
    }
  },
  computed: {},
  methods: {},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
